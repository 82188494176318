import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'gatsby';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import aboutImage from '../../images/croppedImage.png';
import LogoRss from 'react-ionicons/lib/LogoRss';
import EmailListForm from '../forms/EmailListForm';

export default function BigHeader({ title, location, blogPostPage }) {
  const activeChecker = function(url, reactsToBlogPost = false) {
    return location.pathname === url || (reactsToBlogPost && blogPostPage) ? 'active' : '';
  };
  
  const openSubscribePanel = function() {
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Do you want to join my monthly newsletter?',
      customUI: ({ onClose }) => <EmailListForm onClose={onClose} />
    });
  };

  return (
    <div>
      <input className="menu-btn" type="checkbox" id="menu-btn" />
      <label className="menu-icon" htmlFor="menu-btn">
        <span className="navicon"></span>
      </label>

      <div className="big-header-container">
        <div className="big-header-right-side">
          <h1 className="big-header-title">
            <Link to={'/'}>
              {title}
            </Link>
          </h1>
          <p className="big-header-sub-title">
            challenges of engineering management
          </p>
        </div>
        <nav className="nav-container">
          <ul className="menu-header-links-container">
            <li className={`menu-header-link ${activeChecker('/', true)}`}>
              <Link to={'/'}>Blog</Link>
            </li>
            <li className={`menu-header-link ${activeChecker('/talks')}`}>
              <Link to={'/talks'}>Talks</Link>
            </li>
            <li className={`menu-header-link ${activeChecker('/about')}`}>
              <Link to={'/about'}>About</Link>
            </li>
            <li className={`menu-header-link ${activeChecker('/skills')} dropdown-trigger`}>
              <Link to={'#'}>Resources</Link>
              <ul className='dropdown'>
                <li className={`${activeChecker('/skills')}`}><Link to={'/skills'}>Skills Tree</Link></li>
                <li className={`${activeChecker('/recommended')}`}><Link to={'/recommended'}>Personal recommendation</Link></li>
              </ul>
            </li>
            <li className={'menu-header-link'}>
              <Link to="#" onClick={openSubscribePanel}>Subscribe</Link>
            </li>
            <li className={'menu-header-link icon-link'}>
              <a title="RSS feed" target="_blank" href="/rss.xml"><LogoRss fontSize="18px" color="#4d4950"/></a>
            </li>
          </ul>
        </nav>
      </div>

      <div className="header-image-wrapper">
        <img src={aboutImage} alt="Krzysztof Witczak" className="header-image" />
      </div>

    </div>
  );
}

BigHeader.propTypes = {
  title: PropTypes.string.isRequired,
};
