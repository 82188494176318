import PropTypes from 'prop-types';
import React from 'react';

import '../css/layout.scss';

import BigHeader from './headers/BigHeader';

class Layout extends React.Component {
  render() {
    const { title, children, location, blogPostPage } = this.props;
    const headerProps = { title, location, blogPostPage };
    
    return (
      <div className="site-container">
        <header>
          <BigHeader {...headerProps} />
        </header>
        <main className="page-content">
          {children}
        </main>
        <footer>
        </footer>
      </div>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
  title: PropTypes.string.isRequired
};

export default Layout;
