import React from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import Swal from 'sweetalert2';

export default class EmailListForm extends React.Component {
  state = {
      name: null,
      email: null
  };

  handleSubmit = (e) => {
    e.preventDefault();

    addToMailchimp(this.state.email, { FNAME: this.state.name })
      .then((_data) => {
        Swal.fire(
            'Welcome on board! 👋',
            'Check your email box to confirm the subscription! 😃',
            'success')
        .then(() => this.props.onClose());
      })
      .catch((_error) => {
        Swal.fire(
            'Oops... 😣',
            "Something went wrong... Please reach me out on LinkedIn or e-mail through contact, and I'll add you to the list manually.",
            'error')
      })

  };

  handleChange = (name, e) => {
    this.setState({ [name]: e.currentTarget.value });
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="form__group subscribe-form-container">
            <div className="form__group subscribe-input-group">
                <input type="input"
                        id='name'
                        className="form__field"
                        name="name"
                        placeholder="Name"
                        type="text"
                        onChange={(v) => this.handleChange('name', v)}
                        required />
                <label htmlFor="name" className="form__label">Name</label>
            </div>
            <div className="form__group subscribe-input-group">
                <input type="input"
                        id='email'
                        className="form__field"
                        name="email"
                        placeholder="Email"
                        type="text"
                        onChange={(v) => this.handleChange('email', v)}
                        required />
                <label htmlFor="email" className="form__label">Email</label>
            </div>
            <button type="submit" className="btn draw-border subscribe-button">
                Subscribe
            </button>
        </div>
      </form>
    );
  }
};
